.quote-gen {
  padding: 2rem;
  border: 1px solid #c1c1c1;
  border-radius: 1rem;
  width: 65%;
  margin: 2rem auto;
  .main-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1 {
      text-align: center;
      font-family: "Lora", sans-serif;
      font-size: 2.5rem;
    }
    img {
      width: 10%;
    }
    margin-bottom: 1rem;
  }
  .container {
    margin-bottom: 5rem;
    .section {
      margin-bottom: 2.5rem;
      .title {
        h2 {
          font-family: "Lora", sans-serif;
        }
      }
      .body {
        margin-top: 1rem;
        .field-container {
          display: flex;
          align-items: center;
          min-width: 50%;
          width: fit-content;
          justify-content: space-between;
          p {
            font-size: 1.1rem;
            font-family: "Lato", sans-serif;
          }
          button {
            background: rgb(0, 102, 255);
          }
          margin-bottom: 1rem;
          button.remove {
            background: #f00;
          }
          .input-group{
            display: flex;
            align-items: center;
            p{
              margin: 0 .75rem;
            }
          }
        }
        .field-container.validation {
          button {
            background: #505050;
          }
        }
      }
      .control {
        display: flex;
        justify-content: flex-end;
        button.add {
          color: #000;
          background: #fff;
          border: 1px solid #c1c1c1;
        }
      }
    }
    .floor {
      .title {
        h2 {
          font-family: "Lora", sans-serif;
        }
      }
      .control {
        display: flex;
        justify-content: flex-end;
        button.add {
          color: #000;
          background: #fff;
          border: 1px solid #c1c1c1;
        }
      }
    }
  }
  .controls.submit {
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
  }
}
