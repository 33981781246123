.contact {
  padding: 2rem 5rem;
  .title {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0;
    h1 {
      background: url("../../../assets/icons/title-bg-rect.svg");
      font-family: "Lora", sans-serif;
      background-repeat: no-repeat;
      background-size: contain;
      padding: 0.5rem 0 0.5rem 1rem;
    }
  }
  .body {
    display: flex;
    width: 70%;
    margin: auto;
    padding: 3rem 2rem;
    justify-content: space-between;
    box-shadow: 0px 19.194px 25.9119px rgba(0, 0, 0, 0.05);
    border-radius: 1rem;
    overflow: hidden;
    background: url("../../../assets/images/contact-us-bg.svg");
    background-repeat: no-repeat;
    background-position: right;
    .col:nth-child(1) {
      width: 40%;
      .form-title {
        h2 {
          font-family: "Lato", sans-serif;
        }
        p {
          font-family: "Lato", sans-serif;
        }
      }
      .form-body {
        padding: 1rem 0;
        .field-container {
          margin: 1rem 0;
          * {
            font-family: "Lato", sans-serif;
          }
          label {
            font-size: 1vw;
            font-weight: 600;
            color: #000;
            display: block;
          }
          input,
          textarea {
            display: block;
            padding: 0.5rem 1rem;
            border-radius: 0.3rem;
            border: 1px solid #d2d6da;
            font-weight: 500;
            margin: 0.5rem 0;
            width: 100%;
          }
          input::-webkit-input-placeholder {
            color: #c1c1c1;
          }
          textarea::-webkit-input-placeholder {
            color: #c1c1c1;
          }
        }
        .submit {
          text-align: right;
        }
      }
    }
    .col:nth-child(2) {
      width: 35%;
      background-size: cover;
      background-position: right;
      background-repeat: no-repeat;
      * {
        color: #fff;
        font-family: "Lato", sans-serif;
      }
      .content {
        .title {
          display: block;
          h2 {
            text-align: left;
            margin: 0.5rem 0;
          }
        }
        .info {
          .details {
            .detail {
              display: flex;
              align-items: center;
              margin: 0.5rem 0;
              .icon {
                margin-right: 1rem;
                img {
                  width: 1.25rem;
                }
              }
            }
          }
        }
        .social-media {
          display: flex;
          align-items: center;
          margin: 2rem 0;
          .icon{
            margin-right: 3rem;
            img{
                width: 1.5rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width : 768px) {
  .contact{
    padding: 2rem 1rem;
    .title{
      h1{
        font-size: 1.5rem;
      }
      img{
        width: 40%;
        opacity: 0;
      }
    }
    .body{
      flex-direction: column;
      background: url("../../../assets/images/contact-bg-mobile.png");
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: 100% auto;
      .col:nth-child(1){
        width: 90%;
        .form-body{
          .field-container{
            label{
              font-size: .8rem;
            }
          }
        }
      }
      .col:nth-child(2){
        width: 90%;
      }
    }
  }
}