.terms{
    margin: 4rem auto;
    width: 90%;
    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
          font-family: "Lora", sans-serif;
          font-size: 3rem;
          margin-bottom: 0.5rem;
          padding-right: 3rem;
          background: url("../../../assets/icons/title-bg-rect.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center right;
          width: fit-content;
        }
      }
      .desc.main p{
        font-family: 'Lato', sans-serif;
        width: 70%;
        margin: 2rem auto;
      }
      .body{
        width: 70%;
        margin: 3rem auto;
        .section{
            margin-bottom: 4rem;
            .title{
                h2{
                    font-family: 'Lora', sans-serif;
                    margin-bottom: 2rem;
                }
            }
            .desc{
                *{
                    font-family: 'Lato', sans-serif;
                }
                p{
                    margin-bottom: 2rem;
                }
                ol{
                    li{
                        list-style: lower-alpha;
                        margin-bottom: 1rem;
                        list-style-position: inside;
                        ul{
                            margin: 1rem 0 1rem 2rem;
                            li{
                                list-style: disc;
                        list-style-position: inside;

                            }
                        }
                    }
                }
                
            }
        }
      }
}


@media screen and (max-width : 768px) {
    .terms{
        p,li{
            text-align: justify;
        }
        h1,h2,h3{
            text-align: center;
        }
        .title{
            h1{
                font-size: 1.75rem;
                padding-right: 1rem;
            }

        }
        .desc.main p{
            width: 90%;
        }
        .body{
            width: 90%;
        }
    }
}