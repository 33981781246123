.cost-estimator {
  padding: 5rem 0;
  .container {
    padding: 4rem 2rem;
    border: 1px solid #dedede;
    box-shadow: 4px 4px 20px #c1c1c18b;
    border-radius: 1rem;
    width: 90%;
    margin: 0 auto;
    .title {
      h1 {
        text-align: center;
        font-family: "Lora", sans-serif;
        font-size: 2.5rem;
      }
      p {
        text-align: center;
        font-family: "Lato", sans-serif;
      }
      margin-bottom: 2rem;
    }
    .body {
      width: fit-content;
      margin: auto;
      text-align: center;
      .field-container {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          font-family: "Lato", sans-serif;
        }
        .input-wrapper {
          display: flex;
          align-items: center;
          .seperator {
            margin: 0 1rem;
          }
        }
      }
      
      .controls {
        p.error{
          color : #f00;
          font-family: 'Lato', sans-serif;
          margin-bottom: .5rem;
        }
        margin: 2rem 0;
      }
    }
    .estimate-cards {
      display: flex;
      justify-content: space-between;
      align-items: center;
      * {
        font-family: "Lato", sans-serif;
      }
      .estimate-card.inactive{
        opacity: 0.5;
        filter: blur(3px);
      }
      .estimate-card {
        transition: .3s ease;
        background: #fff;
        width: calc(100%/4.2);
        .head {
          padding: 1rem 2rem;
          background: #000;
          p {
            color: #fff;
            font-size: 1.2vw;
          }
        }
        .card-body {
          background-color: #aecc294e;
          .cost-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem;
            .desc {
              p.name {
                font-weight: 600;
                font-size: 1vw;
              }
              p.dimensions{
                font-size: .9vw;
              }
            }
            .cost{
              p{
                font-weight: 600;
                font-size: 1vw;
              }
            }
          }
          .cost-row.total{
            border-top: 1px solid #aecc29;
            .cost{
              p{
                font-size: 1.3vw;
                color: #526400;
              }
            }
          }
        }
      }
      .estimate-card:hover{
        cursor: pointer;
        transform: scale(1.2);
        z-index: 99;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .cost-estimator {
    .container {
      width: 70%;
      .title {
        h1 {
          font-size: 1.5rem;
        }
      }
      .body {
        .field-container {
          flex-direction: column;
          align-items: start;
          p {
            margin-bottom: 0.75rem;
          }
        }
      }
      .estimate-cards{
        flex-direction: column;
        .estimate-card{
          width: 100%;
          margin-bottom: 2rem;
          .head{
            padding: 1rem;
            p{
              font-size: 1rem;
            }
          }
          .card-body{
            .cost-row{
              .desc{
                p.name{
                  font-size: .9rem;
                }
                p.dimensions{
                  font-size: .8rem;
                }
              }
              .cost{
                p{
                  font-size: .9rem;
                }
              }
            }
            .cost-row.total{
              .cost{
                p{
                  font-size: 1rem;
                }
              }
            }
          }
        }
        .estimate-card:hover{
          cursor: auto;
          transform: none;
          z-index: 99;
        }
      }
    }
  }
}
