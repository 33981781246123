.blog-page{
    width: 70%;
    margin: 5% auto;
    *{
        font-family: 'Lato', sans-serif;
    }
    .blog-image{
        margin: 2rem 0;
        img{
            width: 100%;
            height: auto;
            border-radius: 1.5rem;
        }
    }
    .title{
        margin: 2rem 0;
        h1{
            text-align: center;
            color: #252F40;
            font-size: 3vw;
        }
    }
    .desc{
        width: 85%;
        margin: 0 auto;
        p{
            color: #67748E;
            font-size: 1.2vw;
            margin: 1rem 0;
            text-align: justify;
        }
        
    }
}

@media screen and (max-width : 768px) {
    .blog-page{
        width: 90%;
        .blog-image{
            margin: 1rem 0;
            img{
                border-radius: .5rem;
            }
        }
        .title{
            margin: 1rem 0;
            h1{
                font-size: 1.2rem;
            }
        }
        .desc{
            p{
                font-size: 1rem;
            }
        }
    }
}