.footer {
  * {
    font-family: "Lora", sans-serif;
  }
  background: url("../../assets/images/footer-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 12rem 3rem 0 3rem;
  // min-height: 125vh;
  display: flex;
  align-items: end;
  justify-content: center;
  .container {
    .news-letter {
      .title {
        h1 {
          font-size: 3rem;
          color: #fff;
          text-align: center;
        }
      }
      .form {
        width: 60%;
        margin: 2rem auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .col:nth-child(1) {
          width: 60%;
        }
        .col:nth-child(2) {
          width: 40%;
        }
        .field-container {
          // width: 100%;
          margin: 0 0.5rem;
          input {
            width: 94%;
            padding: 1rem 3%;
            border-radius: 0.5rem;
            font-family: "Sen", sans-serif;
          }
          button {
            width: 100%;
            font-family: "Lato", sans-serif;
            padding: 1rem 2rem;
            border-radius: 0.5rem;
            background: #647800;
            font-size: 1rem;
            color: #fff;
            transition: 0.2s ease;
          }
          button:hover {
            cursor: pointer;
          }
        }
      }
    }
    .contact-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5% 0 7% 0;
      .col{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25%;
        img{
          margin-bottom: 1rem;
        }
        p{
            text-align: center;
            font-family: 'Sen', sans-serif;
            color: #fff;
        }
        a{
          font-family: 'Sen', sans-serif;
          color: #fff;
        }
      }
      border-bottom: 2px solid #fff;
    }
    .site-links{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 0;
        .col{
          p{
            color: #fff;
            font-family: 'Sen', sans-serif;
          }
          ul{
            li{
              margin: .5rem 0;
              a{
                color: #fff;
                font-family: 'Sen', sans-serif;
                
              }
            }
          }
        }
    }
    .mini-footer{
        padding-bottom: 1rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        p{
          color: #fff;
          font-family: 'Sen', sans-serif;
        }
        .social-links{
          margin: 2rem;
          display: flex;
          .col{
            margin: 0 1rem;
          }
        }
    }
  }
}

@media screen and (max-width : 992px) {
  .hide-mobile{
    display: none;
  }
  .footer{
    min-height: 100%;
    padding: 12rem 1rem 0 1rem;
    .container{
      *{
        font-size: .8rem;
      }
      .news-letter{
        .title{
          h1{
            font-size: 1.5rem;
          }
        }
        .form{
          width: 90%;
          .field-container{
            input{
              padding: .75rem 5%;
            }
            button{
              padding: .75rem;
              font-size: .8rem;
            }
          }
        }
      }
      .contact-info{
        flex-direction: column;
        padding-top: 0;
        .col{
          width: 60%;
          margin-bottom: 1rem;
          img{
            width: 2rem;
          }
        }
      }
      .site-links{
        padding: 0;
        flex-wrap: wrap;
        justify-content: space-between;
        .col{
          p{
            text-align: center;
          }
          width: 50%;
          margin: 1rem 0 0 0;
          ul{
            li{
              text-align: center;
            }
          }
        }
      }
      .mini-footer{
        .copyright{
          margin-top: 1rem;
        }
        p{
          text-align: center;
        }
      }
    }
  }
}
