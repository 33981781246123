*{
  margin: 0;
  padding: 0;
  color: #080404;
}
html,body{
  overflow-x: hidden;
}
li{
  list-style: none;
}
a{
  text-decoration: none;
}
button{
  border: none;
  padding: none;
}
input{
  outline: none;
  border: none;
}
