.login {
  padding: 5% 5rem;
  .body {
    display: flex;
    width: 70%;
    margin: auto;
    justify-content: space-between;
    box-shadow: 0px 19.194px 25.9119px rgba(0, 0, 0, 0.05);
    border-radius: 1rem;
    overflow: hidden;
    .col:nth-child(1) {
      padding: 3rem 0 3rem 2rem;
      width: 35%;
      .form-title {
        h2 {
          font-family: "Lato", sans-serif;
        }
        p {
          font-family: "Lato", sans-serif;
        }
      }
      .form-body {
        padding: 1rem 0;
        .field-container {
          margin: 2rem 0;
          * {
            font-family: "Lato", sans-serif;
          }
          label {
            font-size: 1vw;
            font-weight: 600;
            color: #000;
            display: block;
          }
          input,
          textarea {
            display: block;
            color: #000;
            padding: 0.5rem 1rem;
            border-radius: 0.3rem;
            border: 1px solid #d2d6da;
            margin: 0.5rem 0;
            width: 100%;
          }
          input::-webkit-input-placeholder {
            color: #c1c1c1;
          }
          textarea::-webkit-input-placeholder {
            color: #c1c1c1;
          }
        }
        .submit {
          text-align: right;
        }
        .forgot-password {
          a {
            font-family: "Lato", sans-serif;
            color: #67748e;
          }
          a:hover {
            color: #000;
          }
        }
      }
    }
    .col:nth-child(2) {
      width: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .login {
    padding: 3rem 2rem;
    .body {
      width: 90%;
      padding: 2rem 5%;
      flex-wrap: wrap;
      .col:nth-child(1){
        width: 90%;
        padding: 0 5%;
        .form-body{
          .field-container{
            margin: 1rem 0;
            label{
              font-size: .8rem;
            }
            input,textarea{
              width: 90%;
              padding: 0.5rem 5%;
            }
          }
          .forgot-password{
            a{
              font-size: .8rem;
            }
          }
        }
      }
      .col:nth-child(2){
        display: none;
      }
    }
  }
}
