$shadow-color : 0px 4px 16px 0px #e4e4e49f;
.navbar {
  display: flex;
  padding: 1rem 3vw;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: $shadow-color;
  -moz-box-shadow: $shadow-color;
  box-shadow: $shadow-color;
  .logo {
    img {
      width: 4rem;
    }
  }
  .nav-links {
    ul {
      display: flex;
      font-size: 1.2vw;
      align-items: center;
      li {
        margin: 0 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
          width: fit-content;
          opacity: 0;
          transition: .2s ease;
        }
        a {
          // font-size: 1.2rem;
          color: #5a5a5a;
          font-family: "Lato", sans-serif;
        }
        a:hover {
          color: #647800;
        }
      }
      li:hover,li.active{
        img{
          opacity:1;
        }
      }
    }
  }
  .ham-menu{
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .navbar {
    .nav-links {
      ul {
        li {
          margin: 1rem;
          a {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    padding: 1rem 2rem;
    .logo{
      img{
        width: 3rem;
      }
    }
    .nav-links {
      border: 1px solid #000;
      height: 100vh;
      padding: 5rem 0;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #fff;
      transform: translateX(100%);
      transition: 0.3s ease;
      z-index: 9;
      ul {
        flex-direction: column;
        li{
          a{
            font-size: 1rem;
          }
        }
      }
    }
    .nav-links.active{
      transform: translateX(0%);
    }
    .ham-menu{
      height: fit-content;
      z-index: 99;
      span{
        border: .1rem solid #697E00;
        border-radius: .5rem;
        display: block;
        width: 1.75rem;
        margin: .2rem 0;
        transition: .2s ease;
      }
    }
    .ham-menu.active{
      span:first-child{
        transform: rotate(45deg) translate(4px,4px);
      }
      span:last-child{
        transform: rotate(-45deg) translate(4px,-4px);
      }
      span:nth-child(2){
        opacity: 0;
      }
    }
    .ham-menu{
      display: block;
    }
  }
}
