@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;500;600;700&display=swap");
html,body{
  scroll-behavior: smooth;
}
button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  font-family: "Lato", sans-serif;
  background: linear-gradient(175.96deg, #b2d600 0.82%, #647800 94.96%);
  color: #fff;
  transition: 0.2s ease;
}
button:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.go-top{
  z-index: 999;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0 1.5rem 1.5rem 0;
  img{
    width: 2.5rem;
    transform: rotate(180deg);
  }
  opacity: 1;
  transition: .3s ease;
}
.go-top.hide{
  opacity: 0;
}
.go-top:hover{
  cursor: pointer;
}