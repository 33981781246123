.blog {
  width: 80%;
  margin: 5rem auto;
  .title {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0;
    h1 {
      background: url("../../../assets/icons/title-bg-rect.svg");
      font-family: "Lora", sans-serif;
      background-repeat: no-repeat;
      background-size: contain;
      padding: 0.5rem 0 0.5rem 1rem;
    }
  }
  .body {
    margin: 2rem 0;
    .blog-cards {
      * {
        font-family: "Lato", sans-serif;
      }
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      .blog-card {
        width: 30%;
        margin: 2rem 0;
        .card-image {
          // overflow: hidden;
          img {
            width: 100%;
            border-radius: 1rem;
          }
        }
        .card-body {
          margin-top: 1rem;
          .card-title{
            h3{
                font-size: 1.2rem;
            }
          }
          .card-desc{
            margin: .75rem 0;
          }
          .read-more{
            a{
                color: #687D00;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width : 768px) {
  .blog{
    margin: 2rem auto;
    .title{
      margin: 1rem 0;
      h1{
        font-size: 1.5rem;
      }
      img{
        width: 50%;
      }
    }
    .body{
      .blog-cards{
        align-items: start;
        .blog-card{
          width: 45%;
          .card-image{
            img{
              border-radius: .5rem;
            }
          }
          .card-body{
            margin-top: .5rem;
            .card-title{
              h3{
                font-size: 1rem
              }
            }
            .card-desc{
              font-size: .8rem;
              margin: .5rem 0;
            }
            .read-more{
              a{
                font-size: .8rem;
              }
            }
          }
        }
      }
    }
  }
}