.faq-main {
  margin: 5rem 0;
  * {
    font-family: "Lato", sans-serif;
  }
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-family: "Lora", sans-serif;
      font-size: 3rem;
      margin-bottom: 0.5rem;
      padding-right: 3rem;
      background: url("../../../assets/icons/title-bg-rect.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center right;
      width: fit-content;
    }
  }
  .body {
    margin: 3rem auto;
    width: 60%;
    .faq {
      margin-bottom: 2rem;
      .question{
        margin-bottom: .75rem;
        h3{
            font-size: 1.25vw;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .question:hover{
        cursor: pointer;
      }
      .answer{
        height: 0;
        transition: .2s ease;
        overflow: hidden;
        p{
            font-size: 1vw;
        }
      }
    }
    .faq.active{
        .answer{
            height: 5rem;
        }
    }
  }
}
@media screen and (max-width : 1100px){
    .faq-main{
        .title{
            h1{
                font-size: 2rem;
                padding-right: 1rem;
            }
            img{
                // width: 40%;
            }
        }
        .body{
            width: 80%;
            margin: 2rem auto;
            .faq{
                .question{
                    h3{
                        font-size: 1rem;
                    }
                }
                .answer{
                    p{
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}
@media screen and (max-width : 768px) {
    .faq-main{
        .title{
            h1{
                font-size: 2rem;
                padding-right: 1rem;
            }
            img{
                width: 40%;
            }
        }
        .body{
            width: 80%;
            margin: 2rem auto;
            .faq.active{
                .answer{
                    height: fit-content;
                }
            }
            .faq{
                .question{
                    h3{
                        font-size: 1rem;
                    }
                }
                .answer{
                    p{
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}