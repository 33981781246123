.quote {
  .container {
    width: 50%;
    margin: 10% auto;
    border: 2px solid #647800;
    border-radius: 1rem;
    padding: 10% 2rem 5% 2rem;
    background: url("../../../assets/images/quote-bg.svg") no-repeat;
    background-size: auto cover;
  }
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5% 0 5% 0;
    h1 {
      font-family: "Lora", sans-serif;
      font-size: 3rem;
      margin-bottom: 0.5rem;
      padding-right: 2rem;
      background-repeat: no-repeat;
      background-position: center right;
      width: fit-content;
    }
  }
  .desc {
    p {
      font-family: "Lato", sans-serif;
      text-align: center;
      margin: 2rem 0;
    }
    p.instruction {
      width: 70%;
      margin: 2rem auto;
    }
  }
  .body {
    * {
      font-family: "Lato", sans-serif;
    }
    width: 50%;
    margin: auto;
    .field-container {
      display: flex;
      flex-direction: column;
      margin: 1rem 0;
      label {
        font-weight: 600;
      }
      input,
      textarea {
        width: 95%;
        padding: 0.75rem 2.5%;
        border: 1px solid #d2d6da;
        border-radius: 0.5rem;
        margin: 0.5rem 0;
        outline: none;
      }
      ul {
        margin-top: 0.5rem;
        padding: 0.5rem;
        border: 1px solid #d2d6da;
        border-radius: 0.5rem;
        li {
          padding: 0.5rem;
          margin: 0.5rem 0;
          border-bottom: 1px solid #d2d6da;
        }
        li:last-child {
          border: none;
        }
        li:hover {
          background-color: #647800;
          color: #fff;
          cursor: pointer;
          border-radius: 0.5rem;
        }
        li.active {
          background-color: #647800;
          color: #fff;
          border-radius: 0.5rem;
        }
      }
    }
    .field-container.submit {
      align-items: center;
    }
    .field-container.terms {
      display: block;
      input {
        width: fit-content;
        margin-right: 1rem;
      }
    }
    .error {
      p {
        color: #f00;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .quote {
    .container {
      width: 75%;
      margin: 5% auto;
      padding: 6rem 5% 2rem 5%;
      .title {
        h1 {
          font-size: 2rem;
        }
        img {
          width: 40%;
        }
      }
      .desc {
        p {
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }
        p.instruction {
          font-size: 0.8rem;
          width: 90%;
          margin: 1rem auto;
          margin-bottom: 2rem;
        }
      }
      .body {
        width: 90%;
        .field-container {
          label {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
