.home {
  .header {
    background: url("../../../assets/images/head-bg.png");
    background-size: cover;
    min-height: 60vh;
    padding: 4rem 0 4rem 5rem;
    .container {
      backdrop-filter: blur(4.90241px);
      background: radial-gradient(
        93.05% 112.13% at 6.95% 94.2%,
        rgba(255, 255, 255, 0.47) 0%,
        rgba(255, 255, 255, 0.29) 100%
      );
      padding: 3rem;
      border-radius: 2rem;
      width: fit-content;
      h1.title {
        font-family: "Lora", sans-serif;
        font-size: 4rem;
        margin-bottom: 1vw;
        color: #fff;
      }
      p.tagline {
        color: #fff;
        margin: 2rem 0;
        font-family: "Lato", sans-serif;
        font-size: 1.5rem;
        span {
          font-weight: 800;
          color: #fff;
        }
      }
      button {
        font-size: 1.5rem;
        padding: 1vw 4rem;
        font-family: "Lora", sans-serif;
        font-weight: 600;
      }
    }
  }
  .services {
    padding: 5rem;
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2rem;
      h1 {
        background: url("../../../assets/icons/title-bg-rect.svg");
        background-size: contain;
        background-repeat: no-repeat;
        padding: 0 0 0 2rem;
        font-family: "Lora", sans-serif;
        font-size: 3rem;
        color: 080404;
        margin: 0 0 0.5rem 0;
      }
    }
    .container {
      .service-head {
        ul.service-head-btns {
          .service-select{
            display: none;
          }
          .drop-down{
            display: flex;
            justify-content: space-between;
          }
          margin: 2rem 0;
          * {
            font-family: "Lato", sans-serif;
          }
          li {
            padding: 0.5rem 1vw;
            border-radius: 0.5rem;
            transition: 0.2s ease;
            font-size: 1vw;
          }
          li:hover {
            cursor: pointer;
            background: linear-gradient(
              175.96deg,
              #b2d600 0.82%,
              #647800 94.96%
            );
            color: #fff;
          }
          li.active {
            background: linear-gradient(
              175.96deg,
              #b2d600 0.82%,
              #647800 94.96%
            );
            color: #fff;
          }
        }
      }
      .service-body {
        margin: 5rem 3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .col {
          width: 45%;
          img {
            width: 100%;
          }
          .title {
            display: block;
            h3 {
              font-family: "Lora", sans-serif;
              font-size: 2rem;
            }
            img {
              width: 20%;
            }
          }
          .content {
            p {
              font-family: "Lato", sans-serif;
              font-size: 1.2vw;
              margin: 1vw 0;
              text-align: justify;
            }
          }
        }
        .col:last-child {
          width: 35%;
          background-color: #fff;
          padding: 3rem;
          box-shadow: 0px 8.81178px 28.6383px -4.40589px rgba(20, 20, 20, 0.1),
            0px 8.81178px 9.91326px -5.50736px rgba(20, 20, 20, 0.06);
          border-radius: 1vw;
        }
      }
    }
  }
  .features {
    padding: 4rem 5rem 0 5rem;
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        font-family: "Lora", sans-serif;
        font-size: 3rem;
        margin-bottom: 0.5rem;
        padding-right: 2rem;
        background: url("../../../assets/icons/title-bg-rect.svg");
        background-repeat: no-repeat;
        background-position: center right;
        background-size: contain;
      }
    }
    .tag-line {
      margin: 2rem 0;
      p {
        text-align: center;
        font-family: "Lato", sans-serif;
        font-size: 2vw;
        span {
          color: #6d8200;
        }
      }
    }
    .body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 2rem 4rem;
      .feature-card {
        width: 40%;
        display: flex;
        margin: 2rem 0;
        .icon {
          margin-right: 2rem;
        }
        .data {
          .title {
            display: block;
            margin-bottom: 1vw;
            h3 {
              text-align: left;
              font-family: "Lora", sans-serif;
              color: #697d00;
              font-size: 1.4vw;
            }
          }
          .content {
            p {
              font-family: "Lato", sans-serif;
              font-size: 1.2vw;
              text-align: justify;
            }
          }
        }
      }
    }
  }
  .stats {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 5rem;
    // background: url('../../../assets/icons/stats-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3rem 0;
    .container {
      padding: 4rem 4%;
      width: 80%;
      border-radius: 1vw;
      display: flex;
      justify-content: space-between;
      background: linear-gradient(170deg, #b1d400 4.95%, #647800 74.11%);
      .stat {
        .stat-value {
          h1 {
            font-size: 3.5rem;
            color: #fff;
            font-family: "Lora", sans-serif;
            text-align: center;
          }
        }
        .stat-name {
          p {
            font-family: "Lato", sans-serif;
            color: #fff;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
  .portfolio {
    .swiper-pagination{
      position: unset;
    }
    padding: 5rem;
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        font-family: "Lora", sans-serif;
        font-size: 3rem;
        margin-bottom: 0.5rem;
        padding-left: 2rem;
        background: url("../../../assets/icons/title-bg-rect.svg");
        background-repeat: no-repeat;
        background-position: center left;
      }
    }
    .body {
      padding: 4rem 0;
      .portfolio-card {
        position: relative;
        overflow: hidden;
        margin: 1vw 4rem 3rem 4rem;

        img {
          border-radius: 1vw;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .content {
          opacity: 0;
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          top: 0;
          padding: 10%;
          width: 80%;
          height: 80%;
          background: linear-gradient(
            267.85deg,
            #afd2009e 3.39%,
            #6478009e 77.18%
          );
          transition: 0.4s ease;
          p,
          a {
            color: #fff;
            text-align: center;
          }
          p.name {
            font-size: 2.5rem;
            text-align: center;
            font-weight: 600;
            font-family: "Lora", sans-serif;
          }
          p.location {
            font-family: "Lato", sans-serif;
            font-size: 1.2rem;
          }
          .link {
            a {
              font-family: "Lato", sans-serif;
              display: flex;
              align-items: center;
              img {
                margin-left: 1vw;
                width: 2rem;
                transition: 0.2s ease;
              }
            }
            a:hover {
              img {
                transform: translateX(0.5rem);
              }
            }
          }
        }
      }
      .portfolio-card:hover {
        .content {
          opacity: 1;
        }
      }
    }
  }
  .clients {
    padding: 2rem 5rem;
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        font-family: "Lora", sans-serif;
        font-size: 3rem;
        margin-bottom: 0.5rem;
        padding-right: 2rem;
        background: url("../../../assets/icons/title-bg-rect.svg");
        background-repeat: no-repeat;
        background-position: center right;
      }
    }
    .body {
      padding: 3rem 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .client-card {
        width: 25%;
        padding: 3rem 2rem;
        margin: 2rem 1vw;
        height: fit-content;
        .client-head {
          .profile-img {
            margin-right: 1vw;
          }
          display: flex;
          align-items: center;
          margin-bottom: 2rem;
          .detail {
            p {
              font-family: "Lato", sans-serif;
              font-size: 0.8rem;
            }
            p.name {
              font-size: 1.2rem;
              font-weight: 600;
            }
          }
        }
        .client-body {
          p {
            font-family: "Lato", sans-serif;
          }
        }
      }
      .client-card:first-child {
        background-color: #f8e8e0;
      }
      .client-card:nth-child(2) {
        background-color: #e6ecfa;
      }
      .client-card:nth-child(3) {
        background-color: #fef6da;
      }
      .client-card:nth-child(4) {
        background-color: #e6ecfa;
      }
      .client-card:nth-child(5) {
        background-color: #fef6da;
      }
      .client-card:nth-child(6) {
        background-color: #f8e8e0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .home {
    .header {
      padding: 5rem 2rem;
      min-height: fit-content;
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        padding: 10% 5%;
        border-radius: 1vw;
        h1.title {
          font-size: 2.25rem;
          text-align: center;
        }
        p.tagline {
          font-size: 1.2rem;
          text-align: center;
        }
        button {
          font-size: 1rem;
          padding: 0.75rem 1.5rem;
        }
      }
    }
    .services {
      padding: 2rem;
      .title {
        margin-bottom: 1vw;
        h1 {
          font-size: 1.75rem;
          padding-left: 1vw;
        }
        img {
          width: 30%;
        }
      }
      .container {
        .service-head {
          ul.service-head-btns {
            flex-direction: column;
            .service-select {
              font-size: 1rem;
              padding: 0.75rem;
              border: 1px solid #878787;
              border-radius: .75rem;
              display: flex;
              justify-content: space-between;
              img{
                width: 1rem;
              }
            }
            .service-select:hover{
              cursor: pointer;
            }
            li {
              font-size: 1rem;
              padding: 0.75rem;
            }
            .drop-down {
              box-shadow: 0px 8.81178px 28.6383px -4.40589px rgba(20, 20, 20, 0.1),
                0px 8.81178px 9.91326px -5.50736px rgba(20, 20, 20, 0.06);
              // padding: 0.5rem;
              flex-direction: column;
              border-radius: 0.5rem;
              z-index: 99;
              height: 0px;
              overflow: hidden;
              background-color: #fff;
              li {
                background-color: #fff;
              }
            }
            .drop-down.active{
              padding: 0.5rem;
              height: fit-content;
              position: absolute;
            }
          }
        }
        .service-body {
          flex-direction: column;
          margin: 0;
          .col {
            width: 80%;
            img {
              width: 100%;
            }
            .title {
              h3 {
                margin: 1vw 0 0.5rem 0;
                font-size: 1.5rem;
              }
              img {
                display: block;
              }
            }
            .content {
              p {
                font-size: .8rem;
              }
            }
          }
          .col:last-child {
            width: 90%;
            padding: 5%;
          }
        }
      }
    }
    .features {
      padding: 2rem;
      .title {
        h1 {
          font-size: 1.75rem;
          padding-right: 1rem;
        }
        img {
          width: 40%;
        }
      }
      .tag-line {
        margin: 1rem 0;
        p {
          font-size: 1rem;
        }
      }
      .body {
        padding: 2rem 1rem;
        .feature-card {
          width: 100%;
          margin: 1rem 0;
          .icon {
            margin-top: 1rem;
            margin-right: 1rem;
            img {
              width: 2.5rem;
            }
          }
          .data {
            .title {
              h3 {
                font-size: 1rem;
              }
              margin-bottom: 0.5rem;
            }
            .content {
              p {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
    .stats {
      margin: 0 0.5rem;
      padding: 0;
      .container {
        flex-wrap: wrap;
        padding: 1rem 5%;
        .stat {
          width: 40%;
          margin: 1vw 0;
          .stat-value {
            h1 {
              font-size: 1.5rem;
            }
          }
          .stat-name {
            p {
              font-size: 1vw;
              text-align: center;
            }
          }
        }
      }
    }
    .portfolio {
      padding: 2rem;
      .title {
        h1 {
          font-size: 1.75rem;
          background-size: contain;
          padding-left: 1rem;
        }
        img {
          width: 40%;
        }
      }
      .body {
        padding: 2rem 0;
        .portfolio-card {
          margin: 0 1vw;
          img {
            border-radius: 1vw;
          }
        }
      }
    }
    .clients {
      padding: 1rem;
      .title {
        h1 {
          font-size: 1.5rem;
          background-size: contain;
          padding-right: 1rem;
          text-align: center;
        }
        img {
          width: 40%;
        }
      }
      .body {
        padding: 1rem 0 2rem 0;
        .client-card {
          margin: 1rem;
          width: 100%;
          padding: 1.5rem 1rem;
          .client-head {
            margin-bottom: 1vw;
            .profile-img {
              img {
                width: 2.5rem;
              }
            }
            .detail {
              p {
                font-size: 0.6rem;
              }
              p.name {
                font-size: 1rem;
              }
            }
          }
          .client-body {
            p {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}
