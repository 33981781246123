.about {
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-family: "Lora", sans-serif;
      font-size: 3rem;
      margin-bottom: 0.5rem;
      padding-right: 2rem;
      background: url("../../../assets/icons/title-bg-rect.svg");
      background-repeat: no-repeat;
      background-position: center right;
      width: fit-content;
    }
  }
  .title.mobile {
    display: none;
  }
  .about-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5rem;
    .col {
      width: 45%;
      .title {
        display: block;
        margin-bottom: 2rem;
      }
      .desc {
        p {
          font-size: 1.2vw;
          margin-bottom: 2rem;
          font-family: "Lato", sans-serif;
          color: #5a5a5a;
          text-align: justify;
        }
      }
    }
  }
  .mission {
    .desc {
      padding: 5rem 5rem 0 5rem;
      margin: 2rem auto;
      width: 70%;
      P {
        font-family: "Lato", sans-serif;
        text-align: center;
        font-size: 1.2vw;
        margin-bottom: 2rem;
      }
    }
    .mission-img {
      img {
        width: 100%;
      }
    }
  }
  .guild {
    padding: 5rem 8rem;
    .container {
      padding-top: 2rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .team-card {
        width: 45%;
        display: flex;
        align-items: center;
        padding: 1rem;
        background-color: #fff;
        border-radius: 0.8rem;
        margin: 2rem 0;
        box-shadow: 0px 18.9308px 25.5566px rgba(0, 0, 0, 0.05);
        .team-person {
          margin-right: 2rem;
        }
        .detail {
          p {
            font-family: "Lato", sans-serif;
          }
          p.name {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 0.2rem;
          }
          p.designation {
            color: #687d00;
            margin-bottom: 1rem;
          }
          p.desc {
            color: #67748e;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .about {
    .title.mobile {
      display: flex;
      align-items: center;
      margin: 2rem 0;
    }
    .title{
      h1 {
        font-size: 1.5rem;
        background-size: contain;
        padding-right: 1rem;
      }
      img{
        width: 30%;
      }
    }
    .about-section {
      flex-direction: column-reverse;
      padding: 2rem;
      .col {
          width: 100%;
          .image{
            img{
              width: 100%;
            }
          }
          .desc{
            margin-top: 1rem;
            p{
              font-size: .8rem;
            }
          }
          .title.desktop {
            display: none;
          }
      }
    }
    .mission{
      .desc{
        margin: 2rem;
        width: fit-content;
        padding: 0;
          p{
            font-size: .8rem;
            color: #5a5a5a;
            text-align: justify;
          }
      }
    }
    .guild{
      padding: 2rem;
      .container{
        .team-card{
          width: 90%;
          margin: 1rem 0;
          .team-person{
            img{
              width: 5rem;
            }
          }
          .detail{
            p.name{
              font-size: 1rem;
            }
            p.designation{
              font-size: .8rem;
              margin-bottom: .5rem;
            }
            p.desc{
              font-size: .7rem;
            }
          }
        }
      }
    }
  }
}
