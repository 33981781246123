.edit-quote {
  padding: 5rem 0;
  //   background-color: #cfcfcf;
  .container {
    margin: 0 auto;
    width: 70%;
    p {
      font-family: "Lato", sans-serif;
    }
    .main-title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h1 {
        text-align: center;
        font-family: "Lora", sans-serif;
        font-size: 2.5rem;
        margin-bottom: 0.5rem;
      }
      img {
        width: 15%;
      }
      margin-bottom: 3rem;
    }
    .body {
      .section {
        padding: 1rem;
        border-radius: 0.75rem;
        background: #fff;
        box-shadow: 2px 2px 10px #c1c1c1d1;
        .title {
          h2 {
            font-family: "Lato", sans-serif;
          }
          margin-bottom: 1rem;
        }
        .text-editor {
          height: 35vh;
          padding-bottom: 2rem;
        }
        .cost {
          p {
            font-weight: 600;
            .value {
              font-weight: normal;
            }
          }
        }
        .body {
          margin-bottom: 2rem;

          .item {
            * {
              font-family: "Lato", sans-serif;
            }
            padding: 0.5rem;
            margin-bottom: 0.2rem;
            border-radius: 0.25rem;
            border: 1px solid #c1c1c1;
            display: flex;
            justify-content: space-between;
            .delete {
              color: #00f;
              text-decoration: underline;
            }
            .delete:hover {
              cursor: pointer;
            }
          }
          .table {
            margin-top: 2rem;
            width: 100%;
            overflow: auto;
            table {
              border-collapse: collapse;
              width: 100%;
              * {
                font-family: "Lato", sans-serif;
              }
              th {
                background: #c1c1c168;
                padding: 0.5rem;
                border: 1px solid #c1c1c1;
                min-width: 5rem;
              }

              tbody {
                tr {
                  td {
                    text-align: center;
                    padding: 0.5rem;
                    border: 1px solid #c1c1c1;
                  }

                  td.delete {
                    color: #00f;
                    text-decoration: underline;
                  }
                  td.delete:hover {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }

        .body.specs-body {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .field-container {
            p {
              margin-right: 0.75rem;
            }
            display: flex;
            align-items: center;
          }
        }
        margin-bottom: 1.5rem;
      }
      .controls.specs {
        display: flex;
        justify-content: flex-start;
        button {
          color: #000;
          background: #fff;
          border: solid 1px #c1c1c1;
        }
        .add-item {
          margin-right: 1rem;
        }
      }
      .section.final {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
        .final-cost {
          * {
            font-family: "Lato", sans-serif;
          }
        }
      }
    }
  }
  .pdf-preview {
    height: 100%;
    width: 100%;
    backdrop-filter: blur(4px);
    position: fixed;
    top: 0;
    right: 0;
    background-color: #0000006c;
    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .container {
        iframe{
          width: 100%;
          height: 80vh;
        }
      }
    }
  }
  .new-cost-model {
    height: 100%;
    width: 100%;
    backdrop-filter: blur(4px);
    position: fixed;
    top: 0;
    right: 0;
    background-color: #0000006c;
    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .container {
        width: 100%;
        position: relative;
        .close-btn {
          font-family: "Lato";
          font-weight: 800;
          position: absolute;
          top: 0;
          right: 0;
          width: 1rem;
          text-align: center;
          padding: 0.1rem;
          height: 1rem;
          margin: 0.5rem 0.5rem 0 0;
          border-radius: 50%;
          border: 1px solid #000;
        }
        .close-btn:hover {
          cursor: pointer;
        }
        width: fit-content;
        background: #fff;
        padding: 2rem;
        border-radius: 0.5rem;
        .field-container {
          display: flex;
          margin-bottom: 1rem;
          justify-content: space-between;
          align-items: center;
          p {
            margin-right: 3rem;
          }
        }
        
      }
      
    }
  }
}


@media screen and (max-width : 768px) {
  .edit-quote{
    
    .container{
      width: 90%;
      .body{
        .section{
          overflow: hidden;
          .title{
            h2{
              font-size: 1.2rem;
            }
          }
        }
        .section.final{
          flex-direction: column;
        }
      }
    }
    .new-cost-model{
      .wrapper{
        .container{
          padding: 2rem .5rem;
        }
      }
    } 
  }
}